<template>
  <div class="details">
    <div class="top">
      <div class="left">
        <div class="user">
          <img src="../../assets/img/case/编组 3@2x.png" alt="" />
          <span>{{ form.patience_name }}</span>
        </div>
        <div class="sex" v-if="form.patience_sex == 1" @change="sexChange()">
          <img src="../../assets/img/case/编组 19@2x.png" alt="" />
          <span>男</span>
        </div>
        <div
          class="sex"
          v-else-if="form.patience_sex == 2"
          @change="sexChange()"
        >
          <img src="../../assets/img/case/编组 27@2x.png" alt="" />
          <span>女</span>
        </div>
        <div class="age">
          <span>{{ form.patience_age }}岁</span>
        </div>
        <div v-if="form.main_diagnosis_name" class="case">
          <img src="../../assets/img/case/编组 20@2x.png" alt="" />
          <span>{{ form.main_diagnosis_name }}</span>
        </div>
        <div>
          <span v-if="form.visitInfoTime"
            >最近访视日期：{{ form.visitInfoTime }}</span
          >
        </div>
      </div>
      <div class="right">
        <div class="chat-btn" @click="toChatPage">
          <img src="@/assets/img/case/chat.png" />沟通交流
        </div>
      </div>
    </div>
    <div class="main">
      <el-tabs type="card" v-model="activeName" :before-leave="tabLeave">
        <el-tab-pane label="访视记录" name="1">
          <visit-record ref="visitRecord" @changeTab="changeTab" @changeTab2="changeTab2"></visit-record>
        </el-tab-pane>
        <el-tab-pane label="处方记录" name="2">
          <!-- <div style="text-align: center;margin-top: 15%;">
            <img style="width: 250px;height: 130px;" src="../../assets/img/zwsj.png" alt="">
            <div>建设中</div>
          </div> -->
          <div class="precription-records">
            <el-form inline class="form-content">
              <el-form-item>
                <el-input
                  placeholder="请输入药品名称查询"
                  v-model="drugName"
                  class="input-with-select"
                >
                  <el-button
                    slot="append"
                    @click="getRecordsData()"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="getRecordsData()" type="primary"
                  >搜索</el-button
                >
                <el-button @click="reset()" type="reset">重置</el-button>
              </el-form-item>
            </el-form>
            <div style="padding: 0px 0 24px">
              <el-button type="primary" @click="exportList">导出</el-button>
            </div>
            <el-table :data="recordsList" v-loading="dataListLoading">
              <el-table-column
                label="序号"
                type="index"
                width="80"
              ></el-table-column>
              <el-table-column
                label="处方类型"
                prop="patience_name"
                width="180"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.prescriptionType == 1"> 中药处方</span>
                  <span v-if="scope.row.prescriptionType == 2">
                    西药/中成药处方</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="开方时间" prop="openingTime" width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.openingTime">
                    {{ scope.row.openingTime }}</span
                  >
                  <span v-else> - </span>
                </template>
              </el-table-column>
              <el-table-column label="处方状态" prop="patience_name" width="100"
                ><template slot-scope="scope">
                  <span v-if="scope.row.status == 3"> 未开方</span>
                  <span v-if="scope.row.status == 1"> 已开方 </span>
                </template></el-table-column
              >
              <el-table-column label="处方金额" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.prescriptionAmount">
                    {{ scope.row.prescriptionAmount }}</span
                  >
                  <span v-else> - </span>
                </template>
              </el-table-column>
              <el-table-column label="服药频次" width="140"
                ><template slot-scope="scope">
                  <span v-if="scope.row.frequency">
                    {{ scope.row.frequency }}</span
                  >
                  <span v-else> - </span>
                </template></el-table-column
              >
              <el-table-column label="服用方式" width="140"
                ><template slot-scope="scope">
                  <span v-if="scope.row.medicationMethod">
                    {{ scope.row.medicationMethod }}</span
                  >
                  <span v-else> - </span>
                </template></el-table-column
              >
              <el-table-column label="单剂次数" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.singleDoses">
                    {{ scope.row.singleDoses }}</span
                  >
                  <span v-else> - </span>
                </template>
              </el-table-column>
              <el-table-column label="总量" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.total"> {{ scope.row.total }}付</span>
                  <span v-else> - </span>
                </template>
              </el-table-column>
              <el-table-column label="药品信息" width="" prop="drugInfo">
              </el-table-column>
              <el-table-column label="服药要求" width="">
                <template slot-scope="scope">
                  <span v-if="scope.row.medicationRequirements">
                    {{ scope.row.medicationRequirements }}</span
                  >
                  <span v-else> - </span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="100px"
                align="right"
                header-align="right"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="handleRecipelInfo(scope.row)"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="主要体征" name="3">
          <SignView v-if="activeName == 3" />
          <!--        <physical></physical>>  -->
        </el-tab-pane>
        <el-tab-pane label="饮食日记" name="4">
          <div style="text-align: center; margin-top: 15%">
            <img
              style="width: 250px; height: 130px"
              src="../../assets/img/zwsj.png"
              alt=""
            />
            <div>建设中</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="指标图表" name="5">
          <!-- <div style="text-align: center; margin-top: 15%">
            <img
              style="width: 250px; height: 130px"
              src="../../assets/img/zwsj.png"
              alt=""
            />
            <div>建设中</div>
          </div> -->
          <Chart v-if="activeName == 5"></Chart>
        </el-tab-pane>
        <!-- <el-tab-pane label="沟通交流" name="6">
          <iframe
            style="width: 100%; height: 600px"
            :src="chatUrl"
            frameborder="0"
          ></iframe>
        </el-tab-pane> -->
        <el-tab-pane label="基本信息" name="7">
          <BasicInformation></BasicInformation>
        </el-tab-pane>
        <el-tab-pane label="随访方案" name="8">
          <div v-loading="loading">
            <div
              style="text-align: center; margin-top: 135px"
              v-if="!followUpList.length"
            >
              <img
                style="width: 223px; height: 122px"
                src="../../assets/img/case/precipitation-blank.png"
                alt=""
              />
              <div class="follow-up-tip">
                当前用户暂无随访中的方案，您可以给他分配方案，开启随访。
              </div>
              <div>
                <el-button type="primary" @click="sendProgram"
                  >分配方案</el-button
                >
              </div>
            </div>
            <div v-else>
              <el-button type="primary" @click="sendProgram"
                >分配方案</el-button
              >
              <div class="follow-up-list">
                <div
                  class="list-item"
                  :class="'bg-' + item.executionStatus"
                  v-for="(item, index) in followUpList"
                  :key="index"
                >
                  <div class="flex" @click="showRecordList(item)">
                    <div class="label label-2" v-if="item.executionStatus == 2">
                      进行中
                    </div>
                    <div class="label label-1" v-if="item.executionStatus == 1">
                      未开始
                    </div>
                    <div class="label label-3" v-if="item.executionStatus == 3">
                      已结束
                    </div>
                    <div class="program-name">{{ item.programName }}</div>
                    <div class="img-box">
                      <img src="@/assets/img/case/into-record.png" />
                    </div>
                  </div>
                  <div class="detail-con">
                    <div class="con-item">
                      <label>执行时间</label>
                      <span
                        >{{ item.executionTime }} ~
                        {{ item.executionEndTime }}</span
                      >
                    </div>
                    <div class="con-item">
                      <label>操作人</label>
                      <span>{{ item.createName }}</span>
                    </div>
                    <div class="con-item">
                      <label>分配时间</label>
                      <span>{{ item.createTimeName }}</span>
                    </div>
                    <div class="con-item">
                      <label>结束时间</label>
                      <span>{{ item.endTime }}</span>
                    </div>
                  </div>
                  <div class="detail-con">
                    <div class="con-item">
                      <label>结束原因</label>
                      <span v-if="item.executionStatus == 3">{{
                        item.openContent ? item.openContent : "-"
                      }}</span>
                      <span v-else>-</span>
                    </div>
                    <div
                      class="over-btn"
                      @click="showOverPlan(item)"
                      v-if="
                        item.executionStatus == 1 || item.executionStatus == 2
                      "
                    >
                      结束
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <recipel-detail
      v-if="showRecipelDetailDialog"
      ref="recipelDetail"
    ></recipel-detail>
    <el-dialog
      title="分配方案"
      :visible.sync="followUpDialog"
      width="800px"
      :close-on-click-modal="false"
    >
      <div class="follow-up-text-tip">确认将所选的用户的分配下述方案：</div>
      <el-form
        :model="addForm"
        label-width="120px"
        :rules="addDataRules"
        ref="addForm"
        class="dialog-form-con"
      >
        <el-form-item label="随访方案" prop="programId">
          <el-select
            placeholder="请选择"
            v-model="addForm.programId"
            clearable
            style="width: 350px"
            filterable=""
          >
            <el-option
              v-for="(item, index) in programList"
              :key="index"
              :value="item.id"
              :label="item.programName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始执行时间" prop="executionTime">
          <el-date-picker
            style="width: 350px"
            v-model="addForm.executionTime"
            type="date"
            placeholder="请选择"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmSend">确定</el-button>
        <el-button @click="followUpDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="随访方案执行记录"
      :visible.sync="followUpRecordDialog"
      width="1200px"
      :close-on-click-modal="false"
    >
      <el-form class="form-content" :model="recordForm" :inline="true">
        <el-form-item label="活动类型">
          <el-select v-model="recordForm.intParam4" style="width: 240px">
            <el-option
              v-for="(item, index) in programActivityTypeList"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="完成状态">
          <el-select v-model="recordForm.intParam3" style="width: 240px">
            <el-option label="已完成" value="1">已完成</el-option>
            <el-option label="未完成" value="2">未完成</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="recordForm.stringParam4"
            type="date"
            placeholder="开始时间"
            value-format="yyyy-MM-dd"
            style="width: 150px"
          >
          </el-date-picker>
          <div class="divider-line"></div>
          <el-date-picker
            v-model="recordForm.stringParam5"
            type="date"
            placeholder="结束时间"
            value-format="yyyy-MM-dd"
            style="width: 150px"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getProgramActivityRecord()" type="primary"
            >搜索</el-button
          >
          <el-button @click="resetRecordForm()" type="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="recordList"
        v-loading="recordLoading"
        style="width: 100%"
      >
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="活动名称" prop="activityName" width="300">
        </el-table-column>
        <el-table-column label="活动类型" width="120">
          <template slot-scope="scope">
            <span
              v-if="scope.row.programActivity && scope.row.programActivity.id"
              >{{ scope.row.programActivity.activityTypeName }}</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="发送时间"
          prop="createTime"
          width="200"
        ></el-table-column> -->
        <el-table-column label="计划完成时间" prop="">
          <template slot-scope="scope"
            >{{ scope.row.executionDay }}&nbsp;{{
              scope.row.executionTime
            }}</template
          >
        </el-table-column>
        <el-table-column label="实际完成时间" prop="completionTime">
          <template slot-scope="scope">{{
            scope.row.completionTime ? scope.row.completionTime : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1"
              ><span class="dot dot-1"></span>已完成</span
            >
            <span v-if="scope.row.type == 2"
              ><span class="dot dot-2"></span>未完成</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination
          @size-change="sizeChangeHandle2"
          @current-change="currentChangeHandle2"
          :current-page="pageIndex2"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize2"
          :total="totalPage2"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="confirmSend">确定</el-button> -->
        <el-button @click="followUpRecordDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="结束方案"
      :visible.sync="overDialog"
      width="800px"
      :close-on-click-modal="false"
    >
      <div v-loading="loadingOver">
        <div style="padding-bottom: 24px">
          确认要结束本条随访方案么？您可以备注下结
        </div>
        <div>
          <el-input v-model="openContent" type="textarea" rows="8"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmOver">确定</el-button>
        <el-button @click="overDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SignView from "./SignView.vue";
import { idCardRegExp, idCardFunction, getAge } from "@/utils";
import RecipelDetail from "./components/recipelDetail";
import qs from "qs";
import moment from "moment";
export default {
  components: {
    VisitRecord: () => import("./visit2/index.vue"),
    Physical: () => import("../physical/Index.vue"),
    BasicInformation: () => import("./Info.vue"),
    Chart: () => import("./Chart.vue"),
    PlanRecord: () => import("./planRecord.vue"),
    SignView,
    RecipelDetail,
  },

  data() {
    return {
      activeName: "1",
      chatUrl: "",
      patiencePrograms: [],
      form: {
        // main_diagnosis_name:this.$route.params.main_diagnosis_name,
        //   patience_sex:this.$route.params.patience_sex,
        //   patience_name:this.$route.params.patience_name,
        //   age:this.$route.params.patience_age,
        //   visitInfoTime:this.$route.params.visitInfoTime,
      },

      flag: true,
      drugName: "",
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      recordsList: [], //处方记录
      dataListLoading: false,
      showRecipelDetailDialog: false,
      followUpList: [],
      followUpDialog: false, //分配随访弹窗显示隐藏
      addForm: {
        programId: "",
        executionTime: "",
      },
      addDataRules: {
        executionTime: [
          {
            required: true,
            message: "请选择执行时间",
            trigger: "blur",
          },
        ],
        programId: [
          { required: true, message: "请选择方案名称", trigger: "change" },
        ],
      },
      programList: [],
      flag: true,
      loading: false,
      followUpRecordDialog: false,
      recordForm: {
        intParam3: "",
      }, //记录条件
      programActivityTypeList: [],
      recordLoading: false, //随访记录加载
      pageIndex2: 1,
      pageSize2: 10,
      totalPage2: 0,
      recordFormId: "", //随访活动id
      recordList: [], //随访活动记录列表
      overDialog: false,
      overId: "",
      openContent: "", //结束原因
      loadingOver: false,
    };
  },

  mounted() {
    this.getInfo();
    this.form = JSON.parse(localStorage.patientInfo);
    this.form.patience_age = getAge(this.form.patience_birth);
    this.chatUrl =
      "https://ky.docteams.cn/about/#/pc?userId=" +
      JSON.parse(localStorage.userInfo).id.substring(0, 16) +
      JSON.parse(localStorage.currentTeam).id.substring(0, 16) +
      "&fromAccountID=" +
      JSON.parse(localStorage.patientInfo).patience_id +
      "&docFlag=true";
  },

  methods: {
    changeTab(newname) {
      this.activeName = newname;
      if (this.activeName == 2) {
        this.getRecordsData();
      }
      if (this.activeName == 8) {
        this.getProgramInfos();
      }
      this.$store.commit("common/updateActiveName", this.activeName);
    },
    changeTab2(val){
      this.activeName = '1';
    },
    getInfo(type) {
      let _this = this;
      _this.loading = true;
      let params = {
        //   id:this.$route.params.id
        stringParam1: JSON.parse(localStorage.patientInfo).id,
      };
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/teamsPatience/info"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.loading = false;

            if (data.data.patiencePrograms.length > 0) {
              _this.patiencePrograms = data.data.patiencePrograms;

              _this.$nextTick(() => {
                _this.$refs.planRecord.init();
              });
            }
          }
        });
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        stringParam1: teamsId,
        // doctorId: doctorId,
        stringParam2: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/visitInfo/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            if (data.data.length > 0) {
              that.form.visitInfoTime = data.data[0].visitTime;
            }
          }
        });
    },
    // 获取处方记录
    getRecordsData() {
      let that = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {
        stringParam2: teamsId,
        stringParam4: JSON.parse(localStorage.patientInfo).patience_id,
        stringParam5: that.drugName,
        intParam1: that.pageSize,
        intParam2: that.pageIndex,
        //  intParam4: 1,
      };
      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/mbglPrescriptionRecords/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.recordsList = data.data;
            that.totalPage = data.totalCount;
            if (that.recordsList.length) {
              that.recordsList.forEach((element) => {
                let arr = [];
                if (
                  element.mbglPrescriptionDrugsList &&
                  element.mbglPrescriptionDrugsList.length
                ) {
                  element.mbglPrescriptionDrugsList.forEach((chr) => {
                    let str = "";
                    if (element.prescriptionType == 1) {
                      str =
                        chr.drugsName +
                        (chr.drugsDose ? chr.drugsDose : "") +
                        (chr.drugsUseUnit ? chr.drugsUseUnit : "");
                    }
                    if (element.prescriptionType == 2) {
                      str =
                        chr.drugsName +
                        // (chr.specifications ? chr.specifications : "") +
                        (chr.singleDoses ? ":一次" + chr.singleDoses : "") +
                        (chr.drugsUseUnit ? chr.drugsUseUnit : "") +
                        (chr.frequency ? "," + chr.frequency : "") +
                        (chr.total ? ",总共" + chr.total : "") +
                        (chr.totalUnit ? chr.totalUnit : "");
                    }
                    arr.push(str);
                  });
                  if (arr.length) {
                    element.drugInfo = arr.join(";");
                  }
                }
              });
            }
          }
          that.dataListLoading = false;
        });
    },
    reset() {
      this.drugName = "";
      this.getRecordsData();
    },
    handelChange(val) {
      this.getRecordsData();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getRecordsData();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getRecordsData();
    },
    // 导出处方记录
    //
    exportList() {
      let that = this;
      let link = document.createElement("a");
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      link.href =
        this.$global.baseURL +
        "/mbglPrescriptionRecords/export?teamsId=" +
        teamsId +
        "&patientId=" +
        JSON.parse(localStorage.patientInfo).patience_id +
        "&drugName=" +
        that.drugName +
        "&status=1";

      link.click();
    },
    // 查看想
    handleRecipelInfo(row) {
      this.showRecipelDetailDialog = true;
      this.$nextTick(() => {
        this.$refs.recipelDetail.init(row);
      });
    },
    // 分配方案
    sendProgram() {
      this.followUpDialog = true;
      this.$nextTick(() => {
        this.$refs.addForm.resetFields();
      });
      this.getProgramNameList();
    },
    // 获取方案名称
    getProgramNameList() {
      let _this = this;
      let currentTeamId = "";
      if (localStorage.currentTeam) {
        currentTeamId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {
        pageSize: 10000,
        pageNo: 1,
        teamsId: currentTeamId,
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/programInfo/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.programList = data.data;
          }
        });
    },
    // 确认发送
    confirmSend() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.addForm.teamId = JSON.parse(localStorage.currentTeam).id;
        _this.addForm.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (_this.form.patience_id) {
        _this.addForm.patienceId = _this.form.patience_id;
      }
      if (localStorage.getItem("userInfo")) {
        this.addForm.hospitalId = JSON.parse(
          localStorage.getItem("userInfo")
        ).deptPid;
        this.addForm.deptId = JSON.parse(
          localStorage.getItem("userInfo")
        ).deptId;
      }
      _this.programList.forEach((ele) => {
        if (ele.id == _this.addForm.programId) {
          _this.addForm.programName = ele.programName;
        }
      });
      console.log(this.addForm);
      // return;
      _this.$refs["addForm"].validate((valid) => {
        if (valid) {
          if (_this.flag) {
            _this.flag = false;
            _this
              .$httpAes({
                url: _this.$http.adornUrl("/patienceProgram/add"),
                method: "post",
                data: _this.addForm,
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.$message({
                    message: data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.followUpDialog = false;
                      _this.flag = true;
                      _this.getProgramInfos();
                    },
                  });
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },
    // 患者分配方案列表详情
    getProgramInfos() {
      let _this = this;
      let currentTeamId = "";
      if (localStorage.currentTeam) {
        currentTeamId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {
        intParam1: 10000,
        intParam2: 1,
        stringParam2: currentTeamId,
        stringParam1: _this.form.patience_id,
      };
      _this.loading = true;
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/patienceProgram/getProgramInfos"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.loading = false;
            _this.followUpList = data.data;
            if (_this.followUpList.length) {
              _this.followUpList.forEach((ele) => {
                if (ele.executionStatus == 1) {
                  ele.sortNum = 2;
                }
                if (ele.executionStatus == 2) {
                  ele.sortNum = 1;
                }
                if (ele.executionStatus == 3) {
                  ele.sortNum = 3;
                }

                if (ele.createTime) {
                  ele.createTimeName = moment(ele.createTime).format(
                    "YYYY-MM-DD"
                  );
                }
                if (ele.executionTime) {
                  ele.executionTime = moment(ele.executionTime).format(
                    "YYYY-MM-DD"
                  );
                }
                if (ele.executionDay) {
                  ele.executionEndTime = moment(ele.executionTime)
                    .add(ele.executionDay, "day")
                    .format("YYYY-MM-DD");
                }
              });
              this.followUpList.sort((a, b) => a.sortNum - b.sortNum); // 通过年龄属性进行排序
            }
          }
        });
    },
    showRecordList(row) {
      this.followUpRecordDialog = true;
      this.getTypeList();
      this.recordForm = {};
      this.recordFormId = row.programId;
      this.getProgramActivityRecord();
    },
    async getTypeList() {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/dict/list"),
        method: "post",
        data: {
          stringParam1: "方案活动类型",
        },
        // params: {
        //   type: "方案活动类型",
        // },
      });
      // console.log(res,'res')
      this.programActivityTypeList = res.data;
    },
    // 重置搜索信息
    resetRecordForm() {
      this.recordForm = {};
    },
    getProgramActivityRecord() {
      let _this = this;
      let currentTeamId = "";
      if (localStorage.currentTeam) {
        currentTeamId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {
        intParam1: _this.pageSize2,
        intParam2: _this.pageIndex2,
        stringParam1: _this.form.patience_id,
        stringParam7: _this.recordFormId,
      };
      params = Object.assign(params, _this.recordForm);
      console.log(params);
      _this.recordLoading = true;
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/mbglPatienceActivity/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.recordLoading = false;
            _this.recordList = data.data;
            _this.totalPage2 = data.totalCount;
          }
        });
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageIndex2 = 1;
      this.getProgramActivityRecord();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageIndex2 = val;
      this.getProgramActivityRecord();
    },
    showOverPlan(row) {
      this.overDialog = true;
      this.overId = row.id;
    },
    confirmOver() {
      let _this = this;
      _this.loadingOver = true;
      let params = {
        stringParam2: _this.openContent,
        stringParam1: _this.overId,
      };
      // if (!params.openContent) {
      //   _this.$message.error("请输入结束方案原因");
      //   return;
      // }
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/patienceProgram/updateStatusById"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.loadingOver = false;
                _this.overDialog = false;
                _this.getProgramInfos();
              },
            });
          } else {
            _this.loadingOver = false;
            _this.$message.error(data.msg);
          }
        });
    },
    // 跳转到聊天会话页面
    toChatPage() {
      // window.open("https://ky.docteams.cn/about/#/about?userId=" +
      //   JSON.parse(localStorage.userInfo).id.substring(0, 16) +
      //   JSON.parse(localStorage.currentTeam).id.substring(0, 16) +
      //   "&docFlag=true")
      let routerUrl = this.$router.resolve({
        name: "chat",
        query: { type: 2 },
      });
      console.log(routerUrl);
      window.open(routerUrl.href, "_blank");
    },
    tabLeave(newname, oldename) {
      console.log("qqqqqqqqqqqqq", newname, oldename);
      // this.$refs.visitRecord.updateSubmitContent2(newname, oldename);
        // if (oldename == 1) {
        //   if (this.$store.state.common.submitContent) {
        //     return this.$confirm("离开将不保存本页修改", "温馨提示", {
        //       confirmButtonText: "确定",
        //       cancelButtonText: "取消",
        //       type: "warning",
        //       closeOnPopstate: false,
        //     })
        //       .then(() => {
        //         this.activeName = newname;
        //         this.changeTab();
        //       })
        //       .catch(() => {
        //         throw new Error("取消成功！");
        //       });
        //   } else {
        //     this.changeTab();
        //   }
        // } else {
        //   this.changeTab();
        // }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.visitRecord.updateSubmitContent();
    setTimeout(() => {
      next(() => {});
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.details {
  .top {
    width: 100%;
    height: 70px;
    background: #f4fbff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    .left {
      display: flex;
      align-items: center;
      font-size: 16px;
      .user {
        margin-right: 40px;
        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
      .sex {
        margin-right: 47px;
        img {
          width: 19px;
          height: 19px;
        }
      }
      .age {
        margin-right: 47px;
        span {
          display: block;
          width: 50px;
          height: 22px;
          background: #faf6f0;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f1800c;
          line-height: 22px;
          text-align: center;
        }
      }
      .case {
        display: flex;
        align-items: center;
        margin-right: 40px;
        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
    .right {
      position: relative;
      padding: 24px 0;
      .chat-btn {
        width: 100px;
        height: 34px;
        background: #ffffff;
        border-radius: 19px;
        border: 1px solid #05dcec;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 22px;
          height: 22px;
          margin-right: 6px;
        }
      }
    }
  }
  .main {
    margin: 26px;
    width: calc(100% - 62px);
    min-height: 500px;
  }
  .follow-up-tip {
    color: #999;
    padding: 10px 0 64px;
  }
  .follow-up-text-tip {
    height: 38px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    background: #e9f7ff;
    margin-bottom: 24px;
  }
  .follow-up-list {
    .list-item {
      // height: 182px;

      border-radius: 8px;
      margin-top: 24px;
      padding: 24px;
      .flex {
        display: flex;
        align-items: center;
        position: relative;
        .program-name {
          font-size: 16px;
        }
        .img-box {
          position: absolute;
          top: 0;
          right: 0;
          img {
            width: 26px;
            height: 26px;
          }
        }
      }
      &.bg-1 {
        background: #fff6f6;
      }
      &.bg-2 {
        background: #fffaf4;
      }
      &.bg-3 {
        background: #f6f6f6;
      }
      .label {
        width: 90px;
        height: 36px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
      .label-2 {
        background: #ffefdd;
        color: #ff8800;
      }
      .label-1 {
        background: #ffe8e8;
        color: #f41717;
      }
      .label-3 {
        background: #e7e7e7;
        color: #999999;
      }
      .detail-con {
        display: flex;
        padding-top: 24px;
        .con-item {
          flex: 1;
          label {
            color: #999;
            display: inline-block;
            width: 84px;
            text-align: right;
          }
          span {
            padding-left: 16px;
          }
        }
        .over-btn {
          width: 59px;
          height: 28px;
          border-radius: 8px;
          border: 1px solid #c9e9ff;
          color: #0099ff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .divider-line {
    width: 20px;
    height: 1px;
    background: #979797;
    display: inline-block;
    margin: 0 10px 0;
  }
  .page-box {
    display: flex;
    justify-content: center;
  }
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-right: 6px;
    vertical-align: middle;
    &.dot-1 {
      background: #10cd30;
    }
    &.dot-2 {
      background: #f41717;
    }
  }
}
</style>

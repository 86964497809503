import VueRouter from 'vue-router'
import Vue from 'vue';
import store from '../store'
import {
  getMenuList,
  getMenuList2,
  getMenuList3,
  shortMenuList,
} from "../utils/menu";
import { isURL } from "@/utils/validate";
import { clearLoginInfo } from "@/utils";
import Login from '@/views/common/login.vue'
import Layout from '@/views/main.vue'
import Home from '@/views/common/home'
import Case from '@/views/case/index'
import CaseCreate from '@/views/case/CaseCreate'
import CaseDetails from '@/views/visit/CaseDetails'
import Invite from '@/views/case/invite'
import Analysis from '@/views/case/analysis'
import { MessageBox } from 'element-ui';
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {
    path: "/404",
    component: () => import("@/views/common/404"),
    name: "404",
    meta: {
      title: "404未找到"
    }
  },
  //肥胖
  {
    path: "/fatLogin",
    component: () => import("@/views/common/fatLogin"),
    name: "fatLogin",
    meta: {
      title: "登录"
    }
  },
  //糖尿病
  {
    path: "/tnbLogin",
    component: () => import("@/views/common/tnbLogin"),
    name: "tnbLogin",
    meta: {
      title: "登录"
    }
  },
  //康复时空健康管理

  {
    path: "/kfskLogin",
    component: () => import("@/views/common/kfskLogin"),
    name: "kfskLogin",
    meta: {
      title: "登录"
    }
  },
  //默认
  {
    path: "/login",
    component: () => import("@/views/common/login"),
    name: "login",
    meta: {
      title: "登录"
    }
  },
  {
    path: "/login2",
    component: () => import("@/views/common/login2"),
    name: "login2",
    meta: {
      title: "登录"
    }
  },
  {
    path: "/zdLogin",
    component: () => import("@/views/common/zdLogin"),
    name: "zdLogin",
    meta: {
      title: "登录"
    }
  },
  {
    path: "/vform-pc",
    component: () => import("@/views/vform-pc"),
    name: "zdLogin",
    meta: {
      title: ""
    }
  },
  {
    path: "/chat",
    component: () => import("@/views/chat/chat"),
    name: "chat",
    meta: {
      title: "沟通交流"
    }
  },
];

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  name: 'layout',
  component: Layout,
  redirect: {
    name: 'login'
  },
  children: [
    {
      path: 'home',
      name: 'home',
      component: Home,
      meta: {
        pUrl: 'home', // 添加该字段，需要相应权限
      }
    },
    {
      path: "case",
      component: Case,
      name: "case",
      meta: {
        title: [
          {
            name: "我的病例",
            linkUrl: ""
          }
        ],
        isTab: false,
        role: 3 | 4
      }
    },
    {
      path: "case/create",
      component: CaseCreate,
      name: "case-create",
      meta: {
        title: [
          {
            name: "添加病例",
            linkUrl: ""
          }
        ],
        isTab: false,
        role: 3 | 4
      }
    },
    //病例详情/访视记录
    {
      path: "visit",
      component: CaseDetails,
      name: "case-details",
      meta: {
        title: [
          {
            name: "我的病例",
            linkUrl: "case"
          },
          {
            name: "病例详情",
            linkUrl: ""
          }
        ],
        isTab: false,
        role: 3 | 4
      }
    },
    // 邀请记录
    {
      path: "case/invite",
      component: Invite,
      name: "invite",
      meta: {
        title: [
          {
            name: "邀请记录",
            linkUrl: ""
          }
        ],
        isTab: false,
        role: 3 | 4
      }
    },
    // 评估分析
    {
      path: "case/analysis",
      component: Analysis,
      name: "analysis",
      meta: {
        title: [
          {
            name: "评估分析",
            linkUrl: ""
          }
        ],
        isTab: false,
        role: 3 | 4
      }
    },
    //队列研究
    {
      path: "queue",
      component: () => import("@/views/queue/Index"),
      name: "queue",
      meta: {
        title: [
          {
            name: "队列研究",
            linkUrl: ""
          }
        ],
        isTab: false,
        role: 3 | 4
      }
    },
    {
      path: "queue/setting",
      component: () => import("@/views/queue/setting"),
      name: "setting",
      meta: {
        title: [
          {
            name: "队列研究设置",
            linkUrl: ""
          }
        ],
        isDynamic: true,
        isTab: false,
        role: 3 | 4
      }
    },
    {
      path: "account/account-info",
      component: () => import("@/views/account/account-info"),
      name: "account-info",
      meta: {
        title: [{ name: "个人信息", linkUrl: "" }],
        isTab: false,
        role: 3 || 4
      }
    },
    // {
    //   path: "chat/chat",
    //   component: () => import("@/views/chat/chat"),
    //   name: "chat",
    //   meta: {
    //     title: [{ name: "聊天消息", linkUrl: "" }],
    //     isTab: false,
    //     role: 3 || 4
    //   }
    // },

    {
      path: "account/pwd-change",
      component: () => import("@/views/account/pwd-change"),
      name: "pwd-change",
      meta: {
        title: [{ name: "个人信息", linkUrl: "" }],
        isTab: false,
        role: 3 || 4
      }
    },
    {
      path: "pwd-change",
      component: import("@/views/account/pwd-change"),
      name: "pwd-change",
      meta: {
        title: [{ name: "个人信息", linkUrl: "" }],
        isTab: false,
        role: 3 || 4
      }
    },
    /**
     * 个人信息
     */
    /**
     * 随访
     */
    {
      path: "followUpList",
      component: () => import("@/views/followUp/followUp"),
      name: "followUpList",
      meta: {

        title: [{ name: "随访管理", linkUrl: "" }, { name: "随访任务", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "waiting",
      component: () => import("@/views/followUp/waiting"),
      name: "waiting",
      meta: {
        title: [{ name: "随访管理", linkUrl: "" }, { name: "待随访", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "followUpList/add",
      component: () => import("@/views/followUp/add"),
      name: "followUpAdd",
      meta: {
        title: [
          { name: "随访管理", linkUrl: "" },
          { name: "创建随访任务", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "followUpList/done",
      component: () => import("@/views/followUp/done"),
      name: "followUpDone",
      meta: {
        title: [
          { name: "随访管理", linkUrl: "followUpList" },
          { name: "完成详情", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "followUpList/info",
      component: () => import("@/views/followUp/info"),
      name: "followUpInfo",
      meta: {
        title: [
          { name: "随访管理", linkUrl: "followUpList" },
          { name: "完成详情", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    /**
     * 方案管理
     */
    {
      path: "zykgl/plan",
      component: () => import("@/views/zykgl/plan/plan"),
      name: "plan",
      meta: {
        title: [{ name: "方案管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "zykgl/plan/add",
      component: () => import("@/views/zykgl/plan/add"),
      name: "planAdd",
      meta: {
        title: [{ name: "方案管理", linkUrl: "plan" }, { name: "方案添加", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "zykgl/plan/edit",
      component: () => import("@/views/zykgl/plan/add"),
      name: "planEdit",
      meta: {
        title: [{ name: "方案管理", linkUrl: "plan" }, { name: "方案编辑", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "zykgl/plan/info",
      component: () => import("@/views/zykgl/plan/info"),
      name: "planInfo",
      meta: {
        title: [{ name: "方案管理", linkUrl: "plan" }, { name: "方案详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    /**
     * 问卷管理
     */

    {
      path: "public",
      component: () => import("@/views/zykgl/public/public"),
      name: "public",
      meta: {
        title: [{ name: "典型案例", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public/add",
      component: () => import("@/views/zykgl/public/add"),
      name: "publicAdd",
      meta: {
        title: [{ name: "典型案例", linkUrl: "public" }, { name: "典型案例添加", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },

    {
      path: "public/edit",
      component: () => import("@/views/zykgl/public/add"),
      name: "publicEdit",
      meta: {
        title: [{ name: "典型案例", linkUrl: "public" }, { name: "典型案例编辑", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public/info",
      component: () => import("@/views/zykgl/public/info"),
      name: "publicInfo",
      meta: {
        title: [{ name: "典型案例", linkUrl: "public" }, { name: "典型案例详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public2",
      component: () => import("@/views/zykgl/public/public"),
      name: "public2",
      meta: {
        title: [{ name: "指南共识", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public2/add",
      component: () => import("@/views/zykgl/public/add"),
      name: "publicAdd2",
      meta: {
        title: [{ name: "指南共识", linkUrl: "public2" }, { name: "指南共识添加", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },

    {
      path: "public2/edit",
      component: () => import("@/views/zykgl/public/add"),
      name: "publicEdit2",
      meta: {
        title: [{ name: "指南共识", linkUrl: "public2" }, { name: "指南共识编辑", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public2/info",
      component: () => import("@/views/zykgl/public/info"),
      name: "publicInfo2",
      meta: {
        title: [{ name: "指南共识", linkUrl: "public2" }, { name: "指南共识详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public3",
      component: () => import("@/views/zykgl/public/public"),
      name: "public3",
      meta: {
        title: [{ name: "康复科普", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public3/add",
      component: () => import("@/views/zykgl/public/add"),
      name: "publicAdd3",
      meta: {
        title: [{ name: "康复科普", linkUrl: "public3" }, { name: "康复科普添加", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },

    {
      path: "public3/edit",
      component: () => import("@/views/zykgl/public/add"),
      name: "publicEdit3",
      meta: {
        title: [{ name: "康复科普", linkUrl: "public3" }, { name: "康复科普编辑", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "public3/info",
      component: () => import("@/views/zykgl/public/info"),
      name: "publicInfo3",
      meta: {
        title: [{ name: "康复科普", linkUrl: "public3" }, { name: "康复科普详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    //新公告
    {
      path: "notice",
      component: () => import("@/views/zykgl/public/notice"),
      name: "notice",
      meta: {
        title: [{ name: "公告消息", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "notice/add",
      component: () => import("@/views/zykgl/public/addNotice"),
      name: "noticeAdd",
      meta: {
        title: [{ name: "公告消息", linkUrl: "notice" }, { name: "公告消息添加", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },

    {
      path: "zykgl/mission",
      component: () => import("@/views/zykgl/mission/mission"),
      name: "mission",
      meta: {
        title: [{ name: "宣教库", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "zykgl/mission/add",
      component: () => import("@/views/zykgl/mission/add"),
      name: "missionAdd",
      meta: {
        title: [{ name: "宣教库", linkUrl: "mission" }, { name: "宣教添加", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "zykgl/mission/edit",
      component: () => import("@/views/zykgl/mission/add"),
      name: "missionEdit",
      meta: {
        title: [{ name: "宣教库", linkUrl: "mission" }, { name: "宣教编辑", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "zykgl/mission/info",
      component: () => import("@/views/zykgl/mission/info"),
      name: "missionInfo",
      meta: {
        title: [{ name: "宣教库", linkUrl: "mission" }, { name: "宣教详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "custom/label",
      component: () => import("@/views/custom/label/label"),
      name: "label",
      meta: {
        title: [{ name: "标签管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "custom/group",
      component: () => import("@/views/custom/group/group"),
      name: "group",
      meta: {
        title: [{ name: "分组管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    // {
    //   path: "custom/teamList",
    //   component: () => import("@/views/team/teamList"),
    //   name: "teamList",
    //   meta: {
    //     title: [{ name: "工作室管理", linkUrl: "" }],
    //     isTab: false,
    //     role: 3
    //   }
    // },
    {
      path: "team/memberAdd",
      component: () => import("@/views/team/memberAdd"),
      name: "memberAdd",
      meta: {
        title: [{ name: "成员管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    //运营菜单
    // {
    //   path: "gzs/teamList",
    //   component: () => import("@/views/gzs/teamList"),
    //   name: "gzsList",
    //   meta: {
    //     title: [{ name: "工作室管理", linkUrl: "" }],
    //     isTab: false,
    //     role: 3
    //   }
    // },
    // {
    //   path: "gzs/memberAdd",
    //   component: () => import("@/views/gzs/memberAdd"),
    //   name: "gzsMemberAdd",
    //   meta: {
    //     title: [{ name: "成员管理", linkUrl: "" }],
    //     isTab: false,
    //     role: 3
    //   }
    // },
    // {
    //   path: "dict/list",
    //   component: () => import("@/views/dict/list"),
    //   name: "dictList",
    //   meta: {
    //     title: [{ name: "字典表", linkUrl: "" }],
    //     name: "字典表",
    //     isTab: false,
    //     auth: true,
    //     ename: "dictList"
    //   }
    // },
    // {
    //   path: "dict/nrfl",
    //   component: () => import("@/views/dict/nrfl"),
    //   name: "dict/nrfl",
    //   meta: {
    //     title: [{ name: "病种管理", linkUrl: "" }],
    //     name: "病种管理",
    //     isTab: false,
    //     auth: true,
    //     ename: "nrfl"
    //   }
    // },
    // {
    //   path: "medicine/medicineList",
    //   component: () => import("@/views/medicine/medicineList"),
    //   name: "medicine/medicineList",
    //   meta: {
    //     title: [{ name: "药品管理", linkUrl: "" }],
    //     name: "药品管理",
    //     isTab: false,
    //     auth: true,
    //     ename: "medicineList"
    //   }
    // },
    // {
    //   path: "medicine/medicineAdd",
    //   component: () => import("@/views/medicine/medicineAdd"),
    //   name: "medicineAdd",
    //   meta: {
    //     title: [
    //       { name: "药品管理", linkUrl: "medicine/medicineList" },
    //       { name: "药品添加", linkUrl: "" }
    //     ],
    //     name: "药品管理",
    //     isTab: false,
    //     auth: true,
    //     ename: "medicineAdd"
    //   }
    // },
    // {
    //   path: "medicine/medicineEdit",
    //   component: () => import("@/views/medicine/medicineEdit"),
    //   name: "medicineEdit",
    //   meta: {
    //     title: [
    //       { name: "药品管理", linkUrl: "medicine/medicineList" },
    //       { name: "药品编辑", linkUrl: "" }
    //     ],
    //     name: "药品管理",
    //     isTab: false,
    //     auth: true,
    //     ename: "medicineEdit"
    //   }
    // },
    // {
    //   path: "medicine/medicineInfo",
    //   component: () => import("@/views/medicine/medicineInfo"),
    //   name: "medicineInfo",
    //   meta: {
    //     title: [
    //       { name: "药品管理", linkUrl: "medicine/medicineList" },
    //       { name: "药品详情", linkUrl: "" }
    //     ],
    //     name: "药品管理",
    //     isTab: false,
    //     auth: true,
    //     ename: "medicineInfo"
    //   }
    // },
    // 问卷管理
    {
      path: "dataCollect",
      component: () => import("@/views/dataCollect/index"),
      name: "dataCollect",
      meta: {
        title: [{ name: "问卷库", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "dataCollect/add",
      component: () => import("@/views/dataCollect/add"),
      name: "dataCollectAdd",
      meta: {
        title: [
          { name: "问卷库", linkUrl: "dataCollect" },
          { name: "添加问卷", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "dataCollect/add",
      component: () => import("@/views/dataCollect/add"),
      name: "dataCollectEdit",
      meta: {
        title: [
          { name: "问卷库", linkUrl: "dataCollect" },
          { name: "编辑问卷", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "dataCollect/info",
      component: () => import("@/views/dataCollect/info"),
      name: "indicatorInfo",
      meta: {
        title: [
          { name: "问卷库", linkUrl: "dataCollect" },
          { name: "问卷管理", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "dataCollect/formInfo",
      component: () => import("@/views/dataCollect/formInfo"),
      name: "formInfo",
      meta: {
        title: [
          { name: "问卷库", linkUrl: "dataCollect" },
          { name: "问卷管理", linkUrl: "indicatorInfo" },
          { name: "查看详情", linkUrl: "" }
        ],
        isTab: false,
        role: 3,
        remark: 2
      }
    },
    {
      path: "dataCollect/preview",
      component: () => import("@/views/dataCollect/preview"),
      name: "preview",
      meta: {
        title: [
          { name: "问卷库", linkUrl: "dataCollect" },
          { name: "预览问卷", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "dataCollect/record",
      component: () => import("@/views/dataCollect/record"),
      name: "indicatorRecord",
      meta: {
        title: [
          { name: "问卷管理", linkUrl: "dataCollect" },
          { name: "问卷记录", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },

    // 数据采集
    {
      path: "feedback/program",
      component: () => import("@/views/feedback/plan/plan"),
      name: "program",
      meta: {
        title: [{ name: "方案内容反馈", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/program/info",
      component: () => import("@/views/feedback/plan/info"),
      name: "",
      meta: {
        title: [{ name: "方案反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/missionary",
      component: () => import("@/views/feedback/missionary/missionary"),
      name: "missionary",
      meta: {
        title: [{ name: "宣教内容反馈", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/missionary/info",
      component: () => import("@/views/feedback/missionary/info"),
      name: "missionary",
      meta: {
        title: [{ name: "宣教反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/followUp",
      component: () => import("@/views/feedback/followUp/followUp"),
      name: "followUp",
      meta: {
        title: [{ name: "问卷内容反馈", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/followUp/info",
      component: () => import("@/views/feedback/followUp/info"),
      name: "followUpInfo",
      meta: {
        title: [{ name: "问卷反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "feedback/followUp/record",
      component: () => import("@/views/feedback/followUp/record"),
      name: "followUpRecord",
      meta: {
        title: [{ name: "问卷反馈详情", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    // 分组管理
    {
      path: "group",
      component: () => import("@/views/group/index"),
      name: "groupList",
      meta: {
        title: [{ name: "分组管理", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "group/autoGroup",
      component: () => import("@/views/group/autoGroup"),
      name: "autoGroup",
      meta: {
        title: [
          { name: "分组管理", linkUrl: "groupList" },
          { name: "自动入组设置", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "quickconfig",
      component: () => import("@/views/quickconfig/index"),
      name: "quickconfig",
      meta: {
        title: [
          { name: "快捷入口配置", linkUrl: "quickconfig" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "basic",
      component: () => import("@/views/basic/index"),
      name: "basic",
      meta: {
        title: [
          { name: "基本信息", linkUrl: "basic" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "sendsform",
      component: () => import("@/views/sendsform/sendsform"),
      name: "sendsform",
      meta: {
        title: [
          { name: "系统发送表单", linkUrl: "sendsform" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "sendsform/add",
      component: () => import("@/views/sendsform/add"),
      name: "sendsformAdd",
      meta: {
        title: [
          { name: "系统发送表单", linkUrl: "sendsform" }, { name: "系统发送表单添加", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "sendsform/edit",
      component: () => import("@/views/sendsform/edit"),
      name: "sendsformEdit",
      meta: {
        title: [
          { name: "系统发送表单", linkUrl: "sendsform" }, { name: "系统发送表单编辑", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "sendsform/info",
      component: () => import("@/views/sendsform/edit"),
      name: "sendsformEdit",
      meta: {
        title: [
          { name: "系统发送表单", linkUrl: "sendsform" }, { name: "系统发送表单详情", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "caseSearch",
      component: () => import("@/views/caseSearch/index"),
      name: "caseSearch",
      meta: {
        title: [
          { name: "病例检索", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "caseSearch/add",
      component: () => import("@/views/caseSearch/add"),
      name: "addCaseSearch",
      meta: {
        title: [
          { name: "病例检索", linkUrl: "caseSearch" },
          { name: "添加检索任务", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "caseSearch/info",
      component: () => import("@/views/caseSearch/info"),
      name: "caseSearchInfo",
      meta: {
        title: [
          { name: "病例检索", linkUrl: "caseSearch" },
          { name: "查看检索任务", linkUrl: "" }
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "teamManage",
      component: () => import("@/views/teamManage/index"),
      name: "teamManage",
      meta: {
        title: [
          { name: "团队管理", linkUrl: "" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "information",
      component: () => import("@/views/information/index"),
      name: "information",
      meta: {
        title: [
          { name: "首页内容", linkUrl: "" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "information/add",
      component: () => import("@/views/information/add"),
      name: "addInformation",
      meta: {
        title: [
          { name: "首页内容", linkUrl: "information" },
          { name: "发布内容", linkUrl: "" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "information/info",
      component: () => import("@/views/information/info"),
      name: "informationDetail",
      meta: {
        title: [
          { name: "首页内容", linkUrl: "information" },
          { name: "发布内容", linkUrl: "" },
        ],
        isTab: false,
        role: 3
      }
    },
    {
      path: "chart/list",
      component: () => import("@/views/chart/list"),
      name: "chart",
      meta: {
        title: [{ name: "系统配置  /  指标图表", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "chartAdd",
      component: () => import("@/views/chart/add"),
      name: "chartAdd",
      meta: {
        title: [{ name: "系统配置  /  指标图表  /  添加指标图表", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "chartEdit",
      component: () => import("@/views/chart/edit"),
      name: "chartEdit",
      meta: {
        title: [{ name: "系统配置  /  指标图表  /  编辑指标图表", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },
    {
      path: "chartInfo",
      component: () => import("@/views/chart/info"),
      name: "chartInfo",
      meta: {
        title: [{ name: "系统配置  /  指标图表  /  查看指标图表", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },

    //研究项目
    {
      path: "project",
      component: () => import("@/views/project/index"),
      name: "project",
      meta: {
        title: [{ name: "项目研究", linkUrl: "" }],
        isTab: false,
        role: 3
      }
    },

  ],

  beforeEnter(to, from, next) {
    let token = localStorage.getItem("token");
    if (!token || !/\S/.test(token)) {
      clearLoginInfo();
      next({
        name: "login"
      });
    }
    next();
  }
};
const router = new VueRouter({
  mode: "hash",
  // scrollBehavior: () => ({
  //   y: 0
  // }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
});
router.beforeEach((to, from, next) => {
  // 患者未提交表单的时候进行路由阻拦提示
  console.log('1111111111111111111111111',store.state.common.submitContent)
  if (store.state.common.submitStatus == 1 && from.name == 'case-details' && store.state.common.activeName == 1 && store.state.common.submitContent) {
    // MessageBox.confirm("离开将不保存本页修改", "温馨提示", {
    //   confirmButtonText: "确定",
    //   cancelButtonText: "取消",
    //   type: "warning",
    //   closeOnPopstate: false,
    // })
    //   .then(() => {
    //     next()
    //   })
    //   .catch(() => {
    //     return;
    //   });
    next()
  } else {
    console.log("to.name", to, from);
    if (to.name == "login") {
      localStorage.setItem("login", "login");
      document.title = "专家数字孪生系统";
      if (location.href.indexOf('zdsnk') != -1) {
        localStorage.setItem("zdLogin", "zdLogin");
        document.title = "东南大学附属中大医院肾脏病诊治中心数据库与病例队列系统";

        router.push({
          name: "zdLogin"
        });
      }
    } else if (to.name == "fatLogin") {
      localStorage.setItem("login", "fatLogin");
      document.title = "肥胖大数据应用创新管理平台";
    } else if (to.name == "tnbLogin") {
      localStorage.setItem("login", "tnbLogin");
      document.title = "悟糖实时血糖管理平台";
    } else if (to.name == "kfskLogin") {
      localStorage.setItem("login", "kfskLogin");
      document.title = "康复时空健康管理";
    }
    if (localStorage.currentTitle) {
      document.title = localStorage.currentTitle;
    }
    let user = JSON.parse(localStorage.getItem("userInfo"));
    let flag = true;
    if (localStorage.gzsFlag == "false") {
      flag = false;
    }
    let pageArr = ['home', 'account-info', 'chat', 'pwd-change'];

    // 校验页面
    // let pageArr = [
    //   "mbscyhList",
    //   "mbscyhInfo",
    //   "mbscyhRecord",
    //   "mbscyhAnalysis",
    //   "mbyhglList",
    //   "mbyhglAnalysis",
    //   "mbyhglInfo",
    //   "mbyhglEditPlan",
    //   "mbyhglRecord",
    //   "medicalRecord",
    //   "followUpList",
    //   "followUpAdd",
    //   "followUpDone",
    //   "plan",
    //   "public",
    //   "mission",
    //   "label",
    //   "label",
    //   "group",
    //   "autoGroup",
    //   "dataCollect",
    //   "dataCollectAdd",
    //   "dataCollectEdit",
    //   "indicatorInfo",
    //   "indicatorRecord"
    // ];

    console.log(pageArr.indexOf(to.name));
    if (
      user &&
      user.roleId == "e89325119fa411e89e43525400557296" &&
      !flag &&
      pageArr.indexOf(to.name) != -1
    ) {
      // router.push({
      //   name: "medicine/medicineList"
      // });
    }

    if (to.name) {
      store.commit("common/updateMenuActiveName", to.name);
      if (to.name == 'case' || to.name == 'case-details' || to.name == 'addCaseSearch') {
        store.commit("common/updateParentMenuActiveName", "case");
      }
      if (to.name == 'case-details') {
        store.commit("common/updateMenuActiveName", 'case');
      }
      if (to.name == 'addCaseSearch') {
        store.commit("common/updateMenuActiveName", "caseSearch");
      }
      if (to.name == 'followUpList' || to.name == 'waiting') {
        store.commit("common/updateParentMenuActiveName", "followUp");
      }
      if (to.name == 'followUpAdd' || to.name == 'followUpDone' || to.name == 'followUpInfo') {
        store.commit("common/updateMenuActiveName", 'followUpList');
        store.commit("common/updateParentMenuActiveName", "followUp");
      }
      if (to.name == 'dataCollectAdd' || to.name == 'dataCollectEdit' || to.name == 'indicatorInfo'
        || to.name == 'formInfo' || to.name == 'preview') {
        store.commit("common/updateMenuActiveName", "dataCollect");
        store.commit("common/updateParentMenuActiveName", "zykgl");
      }
      if (to.name == 'planAdd' || to.name == 'planEdit' || to.name == 'planInfo') {
        store.commit("common/updateMenuActiveName", 'plan');
        store.commit("common/updateParentMenuActiveName", "zykgl");
      }
      if (to.name == 'addInformation' || to.name == 'informationDetail') {
        store.commit("common/updateMenuActiveName", 'information');
        store.commit("common/updateParentMenuActiveName", "information");
      }
      if (to.name == 'missionAdd' || to.name == 'missionEdit' || to.name == 'missionInfo') {
        store.commit("common/updateMenuActiveName", 'mission');
        store.commit("common/updateParentMenuActiveName", "zykgl");
      }
      if (to.name == 'publicAdd' || to.name == 'publicEdit' || to.name == 'publicInfo') {
        store.commit("common/updateMenuActiveName", 'public');
        store.commit("common/updateParentMenuActiveName", "public");
      }
      if (to.name == 'publicAdd2' || to.name == 'publicEdit2' || to.name == 'publicInfo2') {
        store.commit("common/updateMenuActiveName", 'public2');
        store.commit("common/updateParentMenuActiveName", "public");
      }
      if (to.name == 'publicAdd3' || to.name == 'publicEdit3' || to.name == 'publicInfo3') {
        store.commit("common/updateMenuActiveName", 'public3');
        store.commit("common/updateParentMenuActiveName", "public");
      }
      if (to.name == 'noticeAdd') {
        store.commit("common/updateMenuActiveName", 'notice');
        store.commit("common/updateParentMenuActiveName", "notice");
      }
      if (to.name == 'sendsformAdd' || to.name == 'sendsformEdit' || to.name == 'sendsformInfo') {
        store.commit("common/updateMenuActiveName", 'sendsform');
      }
      if (to.name == 'chartAdd' || to.name == 'chartEdit' || to.name == 'chartInfo') {
        store.commit("common/updateMenuActiveName", 'chart');
      }
      if (to.name == 'case-create' || to.name == 'invite' || to.name == 'analysis') {
        store.commit("common/updateParentMenuActiveName", "case");
      }
      if (to.name == 'dataCollect' || to.name == 'plan' || to.name == 'mission') {
        store.commit("common/updateParentMenuActiveName", "zykgl");
      }
      if (to.name == 'public' || to.name == 'public2' || to.name == 'public3') {
        store.commit("common/updateParentMenuActiveName", "public");
      }
      if (to.name == 'label' || to.name == 'groupList' || to.name == 'quickconfig' || to.name == 'sendsform' ||
        to.name == 'sendsformAdd' || to.name == 'sendsformEdit' || to.name == 'sendsformInfo' || to.name == 'chart'
        || to.name == 'chartAdd' || to.name == 'chartEdit' || to.name == 'chartInfo') {
        store.commit("common/updateParentMenuActiveName", "custom");
      }
      if (to.name == 'basic' || to.name == 'teamManage') {
        store.commit("common/updateParentMenuActiveName", "studio");
      }
      if (to.name == 'setting') {
        store.commit("common/updateMenuActiveName", "queue");
        store.commit("common/updateParentMenuActiveName", "queue");
      }
    }
    if (
      fnCurrentRouteType(to, globalRoutes) === "global" ||
      router.options.isAddDynamicMenuRoutes
    ) {
      // if (sessionStorage.getItem("pageArr")) {
      //   let pageArr = JSON.parse(sessionStorage.getItem("pageArr"));
      //   if (pageArr.indexOf(to.name) != -1) {
      //     next();
      //   } else {
      //     MessageBox.alert('当前帐号无权限访问', 'sorry', {
      //       confirmButtonText: '确定',
      //       closeOnHashChange: false
      //     }).then(() => {
      //       setTimeout(() => {
      //         // clearLoginInfo()
      //       }, 1000);
      //     });
      //     return false
      //   }
      //   next();
      // } else {
      //   next();
      // }
      next();
    } else {
      let userInfo = {};
      let userId = "";
      let unionId = "";
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
        userId = userInfo.id;
        unionId = userInfo.doctorUnionId;
      }
      let menuList = ''
      var obj = {};
      var url = window.location.href;             //获取当前url
      let arr1 = url.split('#')
      if (arr1.length > 1) {
        var dz_url = url.split('#')[1];
        let arr2 = dz_url.split('?')
        if (arr2.length > 1) {
          var cs = dz_url.split('?')[1];
          var cs_arr = cs.split('&');                    //参数字符串分割为数组

          for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
            obj[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
          }

        }
      }
      //获取#/之前的字符串
      //获取?之后的参数字符串
      let val = ''
      if (obj.queueFlag != undefined) {
        val = obj.queueFlag
      }
      let roleId = ""
      if (localStorage.currentTeam) {
        let currentTeam = JSON.parse(localStorage.currentTeam);
        if (currentTeam.teamsMembers && currentTeam.teamsMembers.length) {
          roleId = currentTeam.teamsMembers[0].roleIds;
        }
      } else {
        if (localStorage.userInfo) {
          roleId = JSON.parse(localStorage.userInfo).roleId;
        }
      }

      console.log('12144444444', obj)
      if (sessionStorage.radio == 1 && roleId == "e89325119fa411e89e43525400557296") {
        // menuList = shortMenuList()
      } else {

        if (roleId == 'e89325119fa411e89e43525400558291' || roleId == "e89325119fa411e89e43525400557296") {
          menuList = getMenuList();
        }
        if (roleId == 'e89325119fa411e89e43525400558292' || roleId == 'e89325119fa411e89e43525400558293') {
          menuList = getMenuList2();
        }
        if (roleId == 'e89325119fa411e89e43525400558294' || roleId == 'e89325119fa411e89e43525400557298'
          || roleId == 'e89325119fa411e89e43525400558296') {
          menuList = getMenuList3();
        }
      }
      function getarray(obj) {
        obj.children.forEach(element => {
          if (element.resourceUrl) {
            pageArr.push(element.resourceUrl);
          }
          if (element.children && element.children.length > 0) {
            getarray(element);
          }
        });
      }
      // if (menuList.length) {
      //   menuList.forEach((item, index) => {
      //     if (item.children && item.children.length) {
      //       item.children.forEach(chr => {
      //         chr.parentMenuId = item.menuId;
      //       })
      //     }
      //   })
      //   let arr = menuList.filter(v => v.resourceUrl);
      //   let arr2 = menuList.filter(v => !v.resourceUrl);
      //   let newArr = []
      //   let arrName = arr.map(v => { return v.resourceUrl });
      //   let arrName2 = [];
      //   let chidrenList = [];
      //   if (arr2.length) {
      //     arr2.forEach((chr) => {
      //       if (chr.children && chr.children.length) {
      //         chidrenList = chidrenList.concat(chr.children)
      //       }
      //     })
      //   }
      //   arrName2 = chidrenList.map(v => { return v.resourceUrl });
      //   newArr = [...arrName, ...arrName2];
      //   // if (newArr.length) {
      //   //   pageArr = newArr;
      //   //   if (pageArr.indexOf(to.name) != -1) {
      //   //     next({
      //   //       ...to,
      //   //       replace: true
      //   //     })
      //   //   } else {
      //   //     MessageBox.alert('当前帐号无权限访问', 'sorry', {
      //   //       confirmButtonText: '确定',
      //   //     }).then(() => {
      //   //     });
      //   //   }
      //   // }
      //   next({
      //     ...to,
      //     replace: true
      //   })
      //   sessionStorage.setItem("pageArr", JSON.stringify(pageArr));
      // }

      store.commit("common/updateMenuList", menuList);
      changeData(menuList);


      fnAddDynamicMenuRoutes(menuList);
      router.options.isAddDynamicMenuRoutes = true;
      localStorage.setItem("menuList", JSON.stringify(menuList || "[]"));
      next({
        ...to,
        replace: true
      });
    }
  }

});

function changeData(arr) {
  for (var i = 0; i < arr.length; i++) {
    arr[i].list = arr[i].children;
    arr[i].menuId = arr[i].id;
    arr[i].url = arr[i].resourceUrl;
    if (arr[i].children != null && arr[i].children.length != 0) {
      changeData(arr[i].children);
    }
  }
  console.log(arr);
}

function getButton(arr, buttonArr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].children.length != 0 || arr[i].name == "熵孵化") {
      for (var j = 0; j < arr[i].children.length; j++) {
        if (arr[i].children[j].children.length != 0) {
          for (var k = 0; k < arr[i].children[j].children.length; k++) {
            if (arr[i].children[j].children[k].handle != null) {
              buttonArr.push(arr[i].children[j].children[k].handle);
              arr[i].children[j].children.splice(k, 1);
              k--;
            }
          }
        } else if (arr[i].children[j].handle != null) {
          buttonArr.push(arr[i].children[j].handle);
          arr[i].children.splice(j, 1);
          j--;
        }
      }
    } else if (arr[i].handle != null) {
      buttonArr.push(arr[i].handle);

      arr.splice(i, 1);
      i--;
    }
  }
}
/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
  var temp = [];
  console.log(route);
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return "global";
    } else if (
      globalRoutes[i].children &&
      globalRoutes[i].children.length >= 1
    ) {
      temp = temp.concat(globalRoutes[i].children);
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : "main";
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  console.log(menuList);
  console.log(routes);
  var temp = [];
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].list && menuList[i].list.length >= 1) {
      temp = temp.concat(menuList[i].list);
    } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
      menuList[i].url = menuList[i].url.replace(/^\//, "");
      var route = {
        path: menuList[i].url,
        component: null,
        name: menuList[i].url,
        meta: {
          menuId: menuList[i].menuId,
          title: menuList[i].name,
          isDynamic: true,
          isTab: false,
          iframeUrl: ""
        }
      };
      // url以http[s]://开头, 通过iframe展示
      if (isURL(menuList[i].url)) {
        route["path"] = `i-${menuList[i].menuId}`;
        route["name"] = `i-${menuList[i].menuId}`;
        route["meta"]["iframeUrl"] = menuList[i].url;
      } else {
        try {
          // route['component'] = _() => import(`modules/${menuList[i].url}`) || null
          // route["component"] = _import(menuList[i].url) || null;
        } catch (e) { }
      }
      routes.push(route);
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes);
  } else {
    mainRoutes.name = "main-dynamic";
    mainRoutes.children = routes;
    router.addRoutes([
      mainRoutes,
      {
        path: "*",
        redirect: {
          name: "404"
        }
      }
    ]);
    localStorage.setItem(
      "dynamicMenuRoutes",
      JSON.stringify(mainRoutes.children || "[]")
    );
    console.log("\n");
    console.log(
      "%c!<-------------------- 动态(菜单)路由 s -------------------->",
      "color:blue"
    );
    console.log(mainRoutes.children);
    console.log(
      "%c!<-------------------- 动态(菜单)路由 e -------------------->",
      "color:blue"
    );
  }
}
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  console.log('window.location.search', window.location.search)
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null)
    context = decodeURIComponent(r[2]);
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;

}

export default router;
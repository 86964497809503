<template>
  <div
    class="site-wrapper"
    :class="{ 'site-sidebar--fold': sidebarFold }"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
  >
    <!-- <template v-if="!loading"> -->
    <main-navbar />
    <main-sidebar />
    <div
      class="site-content__wrapper"
      :style="{ 'min-height': documentClientHeight + 'px' }"
    >
      <main-content v-if="!$store.state.common.contentIsNeedRefresh" />
    </div>
    <!-- </template> -->
  </div>
</template>

<script>
import MainNavbar from "./main-navbar";
import MainSidebar from "./main-sidebar";
import MainContent from "./main-content";
export default {
  provide() {
    return {
      // 刷新
      refresh() {
        this.$store.commit("common/updateContentIsNeedRefresh", true);
        this.$nextTick(() => {
          this.$store.commit("common/updateContentIsNeedRefresh", false);
        });
      },
    };
  },
  data() {
    return {
      loading: false,
      teamsInfo: {},
    };
  },
  components: {
    MainNavbar,
    MainSidebar,
    MainContent,
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight;
      },
      set(val) {
        this.$store.commit("common/updateDocumentClientHeight", val);
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
    },
    userId: {
      get() {
        return this.$store.state.user.id;
      },
      set(val) {
        this.$store.commit("user/updateId", val);
      },
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      },
      set(val) {
        this.$store.commit("user/updateName", val);
      },
    },
  },
  created() {
    //this.getUserInfo()
  },
  mounted() {
    console.log("123", this.$store.state);
    this.resetDocumentClientHeight();
    if (localStorage.getItem('loginTeamId')) {
      this.getTeamsInfo(localStorage.getItem('loginTeamId'));
    }
  },
  methods: {
    // 重置窗口可视高度
    resetDocumentClientHeight() {
      this.documentClientHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.documentClientHeight = document.documentElement["clientHeight"];
      };
    },
    // 获取当前管理员信息
    getMessage() {
      this.$http({
        url: this.$http.adornUrl("/parkSuggestion/statistics"),
        method: "get",
        params: this.$http.adornParams(),
      }).then(({ data }) => {});
    },
    getTeamsInfo(teamsId) {
      let obj = {
        // pageNo: 1,
        // pageSize: 10,
        // id: teamsId,
		intParam2: 1,
		intParam1: 10,
		stringParam1: teamsId,
      };
      this.$httpAes({
        url: this.$http.adornUrl("/teamsManage/list"),
        method: "post",
        data: obj,
      }).then(({ data }) => {
        if (data.data && data.data.length) {
          this.teamsInfo = data.data && data.data.length ? data.data[0] : "";
          document.title =
            "登录-" + this.teamsInfo.teamsName + "-" + this.teamsInfo.deptName;
          if (this.teamsInfo.avatar) {
            this.setFavicon(this.teamsInfo.avatar);
          }
        }
      });
    },
    setFavicon(src) {
      const link = document.querySelector("link[rel~='icon']");
      if (!link) {
        console.error("Could not find favicon link element");
        return;
      }
      link.href = src;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="list.length">
      <div class="zytz">
        <div
          :class="[activeTab == index ? 'border-'+(item.signType):'',is_show_index == index ?'border-'+(item.signType):'' ]"
          @mouseover="hover(item,index)"
          @mouseleave="leave(item)"
          v-for="(item,index) in list"
          :key="item.id"
          @click="showecharts(item,index)"
          :style="{ background:`url(${require(`../../assets/images/tzbg/tz${item.signType-1}.png`)}) 100% 100%` }"
        >
          <p class="p1">{{item.signTypeName}}</p>
          <p v-if="item.signType!=2" class="p2">{{item.signValue}}{{item.unit}}</p>
		     <p v-else class="p2">{{item.diastolicPressure}}~{{item.systolicPressure}}{{item.unit}}</p>
          <p class="p3">更新时间:{{item.updateTime}}</p>
          <div class="icon icon2" v-if="activeTab == index || item.isshowIcon" @click="edit(item)">
            <img src="../../assets/icon/icon-16.png" alt="">
          </div>
        </div>
      </div>
      <div
        id="main"
        ref="chartContainer"
        style="width: 100%; height: 359px;background-color: #F7F9FB;padding-top: 20px;padding-left: 20px;"
        v-show="isshowecharts"
      ></div>
      <el-dialog :title="`${title}记录`" :visible.sync="dialogVisible" width="800px" :center="false">
        <div class="dialog" v-for="(item,index) in list2" :key="item.id">
          <div class="height" style="padding-right: 32px;">
            <span style="padding-right: 16px;color: #666;">记录时间</span>
            <el-date-picker
              :clearable="false"
              v-model="item.createDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              style="width: 260px;"
            ></el-date-picker>
          </div>
          <div class="height">
            <span style="padding-right: 16px;color: #666;">{{signTypeName}}</span>
            <el-input  v-if="item.signType!=2" v-model="item.signValue" placeholder="请输入内容" style="width: 90px;"></el-input>
			 <el-input  v-if="item.signType==2" v-model="item.diastolicPressure" placeholder="请输入内容" style="width: 90px;"></el-input>
			  <el-input  v-if="item.signType==2" v-model="item.systolicPressure" placeholder="请输入内容" style="width: 90px;"></el-input>
        
			<span style="margin-left: 6px;;">{{unit}}</span>
            <div class="iconbutton" style="padding-left: 23px;">
              <img
                src="../../assets/icon/icon-17.png"
                alt=""
                style="margin-right:10px"
                @click="editIcon(item)"
              >
              <img src="../../assets/icon/icon-18.png" alt="" @click="del(item,index)">
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-empty v-else description="暂无数据"></el-empty>
    <!-- <button @click="add">add</button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
      isshowecharts: true,
      title: "",
      dialogVisible: false, //是否显示dialog
      list: [],
      list1: [],
      list2: [],
      unit: "",
      signTypeName: "",
      currentDate: "",
      signType: 1,
      is_show_index: 0, //移入的序列值
    };
  },

  watch: {
    dialogVisible(newValue, oldValue) {
      if (!newValue) {
        this.getList();
      }
    },
  },

  mounted() {
    this.getList();
    // this.add()
  },

  methods: {
    //切换tab
    showecharts(item, index) {
      this.signType = item.signType;
      this.activeTab = index;
      this.$nextTick(() => {
        this.initChart(item);
      });
    },
    //添加
    add() {
      this.$httpAes({
        url: this.$http.adornUrl("/mbglPatienceSign/add"),
        method: "post",
        data: {
          patienceId: JSON.parse(localStorage.patientInfo).patience_id,
          signValue: 11,
          signType: 6,
        },
      });
    },
    //编辑icon保存
    editIcon(item) {
      this.$httpAes({
        url: this.$http.adornUrl("/mbglPatienceSign/add"),
        method: "post",
        data: {
          patienceId: JSON.parse(localStorage.patientInfo).patience_id,
          id: item.id,
          signValue: item.signValue,
		  diastolicPressure: item.diastolicPressure,
		  systolicPressure: item.systolicPressure,
          createDate: item.createDate,
          signType: item.signType,
        },
      }).then((res) => {
        if (res.data.status) {
          this.$message.success("成功");
        }
      });
    },
    //删除
    async del(item, index) {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/mbglPatienceSign/delete"),
        method: "post",
        data: {
          stringParam1: item.id,
        },
      });
      if (res.status) {
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1000,
          onClose: () => {
            this.list2.splice(index, 1);
            if (this.list2.length == 0) {
              this.dialogVisible = false;
            }
          },
        });
      }
    },

    // //鼠标移入显示icon
    hover(item, index) {
      this.is_show_index = index;
      item.isshowIcon = true;
    },
    //鼠标离开隐藏icon
    leave(item) {
      this.is_show_index = 0;
      item.isshowIcon = false;
    },
    //dialog打开弹窗
    edit(row) {
      this.dialogVisible = true;
      this.title = row.signTypeName;
      this.unit = row.unit;
      this.signType = row.signType;
      this.signTypeName = row.signTypeName;
      this.list2 = this.list1.filter((item) => item.signType == row.signType);
      this.list2 = this.list2.slice(0, 20);
    },
    //获取列表
    getList() {
      this.$httpAes({
        url: this.$http.adornUrl("/mbglPatienceSign/list"),
        method: "post",
        data: {
          stringParam1: JSON.parse(localStorage.patientInfo).patience_id,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          const arr = [];
          if (res.data.data.some((item) => item.signType == 1)) {
            arr.push(res.data.data.find((item) => item.signType == 1));
          }
          if (res.data.data.some((item) => item.signType == 2)) {
            arr.push(res.data.data.find((item) => item.signType == 2));
          }
          if (res.data.data.some((item) => item.signType == 3)) {
            arr.push(res.data.data.find((item) => item.signType == 3));
          }
          if (res.data.data.some((item) => item.signType == 4)) {
            arr.push(res.data.data.find((item) => item.signType == 4));
          }
          if (res.data.data.some((item) => item.signType == 5)) {
            arr.push(res.data.data.find((item) => item.signType == 5));
          }
          if (res.data.data.some((item) => item.signType == 6)) {
            arr.push(res.data.data.find((item) => item.signType == 6));
          }
          this.list = arr;
          this.list1 = res.data.data;
          this.list1 = this.list1.map((item) => {
            if (item.signType == 1) {
              item.signTypeName = "体重";
              item.unit = "KG";
            }
            if (item.signType == 2) {
              item.signTypeName = "血压";
              item.unit = "mmHg";
            }
            if (item.signType == 3) {
              item.signTypeName = "血糖";
              item.unit = "mmol/L";
            }
            if (item.signType == 4) {
              item.signTypeName = "体温";
              item.unit = "℃";
            }
            if (item.signType == 5) {
              item.signTypeName = "脉搏";
              item.unit = "次/分";
            }
            if (item.signType == 6) {
              item.signTypeName = "呼吸";
              item.unit = "次/分";
            }
            return {
              isshowIcon: false,
              ...item,
            };
          });
          this.list = this.list.map((item) => {
            if (item.signType == 1) {
              item.signTypeName = "体重";
              item.unit = "KG";
            }
            if (item.signType == 2) {
              item.signTypeName = "血压";
              item.unit = "mmHg";
            }
            if (item.signType == 3) {
              item.signTypeName = "血糖";
              item.unit = "mmol/L";
            }
            if (item.signType == 4) {
              item.signTypeName = "体温";
              item.unit = "℃";
            }
            if (item.signType == 5) {
              item.signTypeName = "脉搏";
              item.unit = "次/分";
            }
            if (item.signType == 6) {
              item.signTypeName = "呼吸";
              item.unit = "次/分";
            }
            return {
              isshowIcon: false,
              ...item,
            };
          });

          this.$nextTick(() => {
            this.initChart(this.list[this.activeTab]);
          });
        }
      });
    },

    initChart(row) {
      if (row) {
        this.list2 = this.list1.filter((item) => item.signType == row.signType);
        this.list2.sort(
          (a, b) => new Date(a.createDate) - new Date(b.createDate)
        );
        this.list2 = this.list2.slice(0, 20);
        const x = this.list2.map((item) => item.createDate);
        const y = this.list2.map((item) => item.signValue);
        var chartDom = document.getElementById("main");
        var myChart = echarts.init(chartDom);
        let unitName = this.list.length
          ? this.list2[0].signTypeName + "(" + this.list2[0].unit + ")"
          : "";
        const option = {
          title: {
            text: unitName,
          },
          tooltip: {
            trigger: "axis",
            formatter:
              "{b} <br/> {a}: {c}" + this.list.length ? this.list2[0].unit : "", //提示框的内容
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: x,
            interval: 0,
            axisLabel: {
              interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: -45, //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
            },
          },
          grid: {
            top: 50,
            left: 30,
            right: 50,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: row.signTypeName,
              type: "line",
              color: ["#1890FF"],
              symbol: "circle",
              areaStyle: {
                normal: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(91, 143, 249, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(148, 194, 253, 0.05)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
              smooth: true,
              data: y,
            },
          ],
        };
        //修改标题
        myChart.setOption(option);
        window.addEventListener("resize", function() {
          myChart.resize();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iconbutton {
  display: flex;
}
.box {
  cursor: pointer;
}
.box1 {
  cursor: pointer;
  // border: 1px solid rgb(9, 148, 248);
}
.dialog {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    cursor: pointer;
  }
  .height {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sp1 {
      width: 50px;
    }
    .sp2 {
      width: 100px;
    }
  }
}
.zytz {
  padding: 20px;
  display: flex;
  // justify-content: space-around;
  flex-wrap: wrap;
  div {
    width: 16%;
    height: 164px;
    margin-bottom: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    margin-right: 20px;
    cursor: pointer;
    // &:nth-child(1) {
    //   &:hover {
    //     border: 1px solid #ffbca9;
    //   }
    // }
    // &:nth-child(2) {
    //   &:hover {
    //     border: 1px solid #b1efa6;
    //   }
    // }
    // &:nth-child(3) {
    //   &:hover {
    //     border: 1px solid #9edeec;
    //   }
    // }
    // &:nth-child(4) {
    //   &:hover {
    //     border: 1px solid #c2c2f8;
    //   }
    // }
    // &:nth-child(5) {
    //   &:hover {
    //     border: 1px solid #e5c2f5;
    //   }
    // }
    // &:nth-child(6) {
    //   &:hover {
    //     border: 1px solid #f8a5bc;
    //   }
    // }
    &.border-1 {
      border: 1px solid #ffbca9;
    }
    &.border-2 {
      border: 1px solid #b1efa6;
    }
    &.border-3 {
      border: 1px solid #9edeec;
    }
    &.border-4 {
      border: 1px solid #c2c2f8;
    }
    &.border-5 {
      border: 1px solid #e5c2f5;
    }
    &.border-6 {
      border: 1px solid #f8a5bc;
    }
    &:last-child {
      margin-right: 0;
    }
    .icon {
      position: absolute;
      margin-bottom: 0;
    }

    .icon2 {
      /* 第二个图标的样式 */
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
      margin-bottom: 0;
      border: none;
      cursor: pointer;
    }
    p {
      text-align: center;
      width: 100%;
      margin: 10px 0;
    }
    .p1 {
      font-size: 20px;
    }
    .p2 {
      font-size: 22px;
      font-weight: bold;
    }
    .p3 {
      color: rgb(116, 118, 118);
    }
  }
}
</style>
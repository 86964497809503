<template>
  <div>
    <custom-home v-if="customHome"></custom-home>
    <div class="home-page" v-else>
      <!-- <el-breadcrumb separator="/">
			<el-breadcrumb-item>首页</el-breadcrumb-item>
		</el-breadcrumb>
    <div class="tip-con">{{userName}},您好,欢迎进入专家数字孪生系统平台！上次登录时间为：{{lastloginTime}}</div> -->
      <div>
        <div style="display: flex; justify-content: space-between">
          <div class="left" v-loading="loading">
            <div class="number">
              <div class="title">人员数据汇总</div>
              <div class="cnt">
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">累计用户数</div>
                    <div class="num">{{ left.totalPeople }}</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 2.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">累计筛查用户数</div>
                    <div class="num">{{ left.screeningPeople }}</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 3.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">累计患者用户</div>
                    <div class="num">{{ left.diagnosisPeople }}</div>
                  </div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 4.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">近7日平均新增筛查用户</div>
                    <div class="num">{{ left.sevenDayScreeningPeople }}</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 5.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">近7日平均新增患者用户</div>
                    <div class="num">{{ left.sevenDayDiagnosisPeople }}</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 6.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">近30日平均新增筛查用户</div>
                    <div class="num">{{ left.thirtyDayScreeningPeople }}</div>
                  </div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 7.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">近30日平均新增患者用户</div>
                    <div class="num">{{ left.thirtyDayDiagnosisPeople }}</div>
                  </div>
                  <div class="line"></div>
                </div>
                <div class="one">
                  <div class="icon">
                    <img src="../../assets/images/编组 3备份 8.png" alt="" />
                  </div>
                  <div class="con">
                    <div class="text">累计团队成员数</div>
                    <div class="num">{{ left.teamsMembers }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-loading="loading2">
            <div class="activity">
              <div class="title">
                <div class="text">活动完成汇总</div>
                <div class="year">
                  选择年份
                  <el-date-picker
                    @change="getTop2"
                    v-model="year"
                    type="year"
                    value-format="yyyy"
                    placeholder="选择年"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="select">
                <el-radio-group v-model="radio" @change="changeEcharts">
                  <el-radio :label="1">异常活动数</el-radio>
                  <el-radio :label="2">异常人数</el-radio>
                </el-radio-group>
              </div>
              <div id="main"></div>
            </div>
          </div>
        </div>
        <div class="content" v-loading="loading2">
          <div class="title">
            <div class="text">内容数据汇总</div>
            <div class="select">
              <el-radio-group v-model="radio2" @change="getTop2">
                <el-radio :label="null">总计</el-radio>
                <el-radio :label="1">今天</el-radio>
                <el-radio :label="2">昨天</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="cnt">
            <div class="one bg">
              <div class="line">
                <div class="icon">
                  <img src="../../assets/images/数据统计.png" alt="" />
                </div>
                <div class="text">问卷发送总人次</div>
              </div>
              <div class="num">{{ left2.followUpCount }}</div>
            </div>
            <div class="two bg">
              <div class="line">
                <div class="icon">
                  <img src="../../assets/images/数据统计备份.png" alt="" />
                </div>
                <div class="text">宣教发送总人次</div>
              </div>
              <div class="num">{{ left2.noticeCount }}</div>
            </div>
            <div class="three bg">
              <div class="line">
                <div class="icon">
                  <img src="../../assets/images/数据统计备份 2.png" alt="" />
                </div>
                <div class="text">方案发送总人次</div>
              </div>
              <div class="num">{{ left2.programCount }}</div>
            </div>
            <div class="four bg">
              <div class="line">
                <div class="icon">
                  <img src="../../assets/images/数据统计备份 3.png" alt="" />
                </div>
                <div class="text">执行中的方案数量</div>
              </div>
              <div class="num">{{ left2.patienceProgramCount }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <div class="abnormal">
          <div class="title">执行异常数据汇总</div>
          <div class="table">
            <el-table
              :data="abnormalList"
              style="width: 100%"
              :header-cell-style="{
                background: '#FAFAFA',
                color: '#000000',
                height: '55px',
              }"
              v-loading="dataListLoading"
              :default-sort="{ prop: 'date', order: 'descending' }"
            >
              <el-table-column
                prop="patienceName"
                label="姓名"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="totalActivityCount"
                label="异常活动数量"
                header-align="center"
                align="center"
                sortable
              >
              </el-table-column>
              <el-table-column
                prop="count"
                label="截止目前生效活动数量"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="bfb"
                label="活动异常百分比"
                header-align="center"
                align="center"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </div>
        <div class="program">
          <div class="title">
            <div class="text">方案模板使用汇总</div>
            <div class="year">
              选择日期
              <el-date-picker
                @change="getProgramList"
                v-model="time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="table">
            <el-table
              :data="programList"
              style="width: 100%"
              :header-cell-style="{
                background: '#FAFAFA',
                color: '#000000',
                height: '55px',
              }"
              v-loading="dataListLoading2"
            >
              <el-table-column
                prop="program_name"
                label="方案模版名称"
                header-align="center"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="count"
                label="发送总人数"
                header-align="center"
                align="center"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="sizeChangeHandle2"
              @current-change="currentChangeHandle2"
              :current-page="pageNo2"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize2"
              :total="totalPage2"
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import { clearLoginInfo } from "@/utils";
import * as echarts from "echarts";
import moment from "moment";
import customHome from "./home2";
export default {
  components: {
    customHome,
  },
  data() {
    return {
      userName: "",
      type: null,
      list: [], //权限列表
      passWord: "",
      passWord2: "",
      dialogVisible: false,
      lastloginTime: "",
      teamsId: "",
      left: {
        diagnosisPeople: 0,
        followUpCount: 0,
        noticeCount: 0,
        patienceProgramCount: 0,
        programCount: 0,
        screeningPeople: 0,
        sevenDayDiagnosisPeople: 0,
        sevenDayScreeningPeople: 0,
        teamsMembers: 0,
        thirtyDayScreeningPeople: 0,
        thirtyDayDiagnosisPeople: 0,
        totalPeople: 0,
      },
      left2: {},
      activityCount: [],
      year: "",
      radio: 1,
      loading: false,
      loading2: false,
      loading3: false,
      radio2: null,
      dataListLoading: true, //列表loading
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      abnormalList: [],
      time: ["", ""],
      programList: [],
      dataListLoading2: true, //列表loading
      pageNo2: 1,
      pageSize2: 10,
      totalPage2: 0,
      customHome: false,
    };
  },
  watch: {
    time: function (v) {
      if (v == null) {
        this.time = ["", ""];
        this.getProgramList();
      }
    },
  },
  computed: {
    documentClientHeight: {
      get() {
        return this.$store.state.common.documentClientHeight;
      },
      set(val) {
        this.$store.commit("common/updateDocumentClientHeight", val);
      },
    },
    // siteContentViewHeight () {
    //   var height = this.documentClientHeight - 50 - 30 - 2
    //   return  height
    // }
  },

  mounted() {
    this.resetDocumentClientHeight();
    this.year = new Date().getFullYear().toString();
    console.log(this.year);
    this.userName = JSON.parse(localStorage.getItem("userInfo")).name;
    this.type = this.$cookie.get("type");
    this.lastloginTime = JSON.parse(
      localStorage.getItem("userInfo")
    ).lastloginTime;
    //    let arr = JSON.parse(localStorage.getItem('dynamicMenuRoutes'))
    //    for (let i = 0; i < arr.length; i ++) {
    //      this.$set(arr[i], 'imgUrl', require('@/assets/img/home/icon-'+(i+1)+'.png'))
    //    }
    // console.log('arr')
    //    this.list = arr
    if (localStorage.isUpdate == "true") {
      this.dialogVisible = true;
    }
    let teamsId = "";
    let enertyId = "";
    if (localStorage.currentTeam) {
      teamsId = JSON.parse(localStorage.currentTeam).id;
      enertyId = JSON.parse(localStorage.currentTeam).entityId;
    }
    this.teamsId = teamsId;
    if (this.teamsId == "cb39453c4d124fbd9ee771615820eb73") {
      this.customHome = true;
    }
    this.getTop();
    this.getTop2();
    this.getAbnormalList();
    this.getProgramList();
  },
  methods: {
    resetDocumentClientHeight() {
      this.documentClientHeight = document.documentElement["clientHeight"] - 60;
      window.onresize = () => {
        this.documentClientHeight =
          document.documentElement["clientHeight"] - 60;
      };
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getAbnormalList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getAbnormalList();
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getProgramList();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getProgramList();
    },
    async getProgramList() {
      this.dataListLoading2 = true;
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/homePage/getProgramInfo"),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          stringParam2: this.time[0],
          stringParam3: this.time[1],
          intParam1: this.pageSize2,
          intParam2: this.pageNo2,
        },
      });
      console.log(res, "program");
      // res.data.forEach(v=>{
      //   v.bfb = Math.round((v.count / v.totalActivityCount) * 10000) / 100.0 + "%";
      // })
      if (res.status) {
        this.dataListLoading2 = false;
        this.programList = res.data;
        this.totalPage2 = res.totalCount;
      }
    },
    async getAbnormalList() {
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/homePage/getProgramActivity"),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          intParam3: 2,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
        },
      });
      console.log(res);
      res.data.forEach((v) => {
        v.bfb =
          Math.round((v.count / v.totalActivityCount) * 10000) / 100.0 + "%";
      });
      if (res.status) {
        this.dataListLoading = false;
        this.abnormalList = res.data;
        this.totalPage = res.totalCount;
      }
    },
    async getTop() {
      this.loading = true;
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/homePage/getSummarizeData"),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          stringParam2: this.year,
          intParam1: this.radio2,
        },
      });
      console.log(res, "res");
      if (res.status) {
        this.left = res.data;
        // this.activityCount = res.data.activityCount;
      }
      await this.changeEcharts();
      this.loading = false;
    },
    async getTop2() {
      this.loading2 = true;
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/homePage/getSummarizeData2"),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          stringParam2: this.year,
          intParam1: this.radio2,
        },
      });
      if (res.status) {
        this.activityCount = res.data.activityCount;
        this.left2 = res.data;
        this.getTop3();
      }
      await this.changeEcharts();
      this.loading2 = false;
    },
    async getTop3() {
      this.loading3 = true;
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/homePage/getSummarizeData3"),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          stringParam2: this.year,
          intParam1: this.radio2,
        },
      });
      if (res.status) {
        this.left2 = Object.assign({}, this.left2, res.data);
      }
      this.loading3 = false;
    },
    changeEcharts() {
      let activityArr = new Array(12).fill(0);
      let patienceArr = new Array(12).fill(0);
      if (this.activityCount.length) {
        this.activityCount.forEach((v) => {
          // console.log(Number(v.executionDay.slice(5,8)));
          // if(v.executionDay.slice(6,8)){

          // }
          activityArr[Number(v.executionDay.slice(5, 8)) - 1] = v.activityCount;
          patienceArr[Number(v.executionDay.slice(5, 8)) - 1] = v.patienceCount;
        });
      }
      // console.log(activityArr,patienceArr);
      let value = [1, 2, 3, 4, 5];
      let option = {
        xAxis: {
          type: "category",
          data: [
            "一月",
            "二月",
            "三月",
            "四月",
            "五月",
            "六月",
            "七月",
            "八月",
            "九月",
            "十月",
            "十一月",
            "十二月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.radio == 1 ? activityArr : patienceArr,
            type: "bar",
          },
        ],
      };
      let myChart = echarts.init(document.getElementById("main"));
      myChart.setOption(option);
      // 当浏览器宽度改变的时候，图表的大小也跟着改变
      window.onresize = () => {
        myChart.resize();
      };
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
/deep/.el-card__body {
  padding: 0 !important;
  background: #f4f4f4;
}
.home-page {
  // padding: 20px;
  height: 100%;
  // display: flex;
  .tip-con {
    padding: 30px 0;
  }
  .auth-list {
    padding: 80px 40px 0;
    background: #f5f5f5;
    overflow: hidden;
    .list-item {
      width: 16%;
      margin-right: 5%;
      height: 180px;
      float: left;
      background: rgba(255, 255, 255, 1);
      box-shadow: -5px 0px 12px 0px rgba(209, 207, 207, 0.43);
      border-radius: 6px;
      margin-bottom: 50px;
      cursor: pointer;
      &:nth-child(5n) {
        margin: 0;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
    }
  }

  .left {
    flex-basis: 49%;
    // background-color: red;
    height: 100%;
    .number {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 450px;
      border-radius: 5px;
      .title {
        border-bottom: 1px solid #eee;
        font-size: 18px;
        height: 57px;
        padding-left: 32px;
        line-height: 57px;
        font-weight: bold;
      }
      .cnt {
        display: flex;
        // flex-direction:column;
        height: 391px;
        // padding: 40px 0;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding-left: 32px;
        padding-top: 40px;
        .one {
          position: relative;
          display: flex;
          height: 72px;
          padding-left: 15px;
          // min-width: 220px;
          // justify-content: space-between;
          flex-basis: 30%;
          // padding-left: 32px;
          margin-right: 3%;
          box-sizing: border-box;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .icon {
            align-items: center;
            width: 47px;
            height: 72px;
            display: flex;
            img {
              width: 100%;
              height: 47px;
            }
          }
          .con {
            display: flex;
            flex-direction: column;
            margin-left: 18px;
            justify-content: space-between;
            .num {
              font-size: 26px;
              font-weight: bold;
            }
          }
          .line {
            position: absolute;
            right: 0;
            width: 1px;
            height: 41px;
            background: #eee;
            margin-top: 16px;
            // margin-right: 20px;
          }
        }
      }
    }
    // margin-right: 24px;
  }

  .right {
    flex-basis: 49%;
    height: 100%;
    // background-color: blue;
    .activity {
      display: flex;
      flex-direction: column;

      .title {
        border-bottom: 1px solid #eee;
        height: 57px;
        padding-left: 32px;
        line-height: 57px;
        display: flex;
        justify-content: space-between;
        .text {
          font-size: 18px;
          font-weight: bold;
        }
      }
      .select {
        padding-left: 32px;
        height: 51px;
        line-height: 51px;
        border-bottom: 1px solid #eee;
      }
      #main {
        height: 337px;
        width: 100%;
        div {
          width: 100%;
        }
        canvas {
          width: 100%;
        }
      }
    }
  }
  /deep/.el-radio__input {
    display: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 272px;
    .title {
      border-bottom: 1px solid #eee;
      height: 57px;
      padding-left: 32px;
      line-height: 57px;
      display: flex;
      justify-content: space-between;
      .text {
        font-size: 18px;
        font-weight: bold;
      }
    }
    .cnt {
      height: 214px;
      padding: 30px;
      display: flex;
      // justify-content: space-between;
      .bg {
        // width: 362px;
        flex: 1;
        height: 153px;
        background-image: url("../../assets/images/12.png");
        padding: 30px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        .line {
          height: 30px;
          display: flex;
          img {
            width: 30px;
            height: 30px;
          }
          .text {
            line-height: 28px;
            color: #072d6c;
            margin-left: 24px;
          }
        }
      }
      .one {
        background-image: url("../../assets/images/12.png");
        .num {
          font-size: 30px;
          color: #3d85fe;
          margin-left: 52px;
          margin-top: 20px;
        }
      }
      .two {
        background-image: url("../../assets/images/12备份.png");
        .num {
          font-size: 30px;
          color: #00d1a2;
          margin-left: 52px;
          margin-top: 20px;
        }
      }
      .three {
        background-image: url("../../assets/images/12备份 2.png");
        .num {
          font-size: 30px;
          color: #9d68f5;
          margin-left: 52px;
          margin-top: 20px;
        }
      }
      .four {
        background-image: url("../../assets/images/12备份 3.png");
        .num {
          font-size: 30px;
          color: #f47022;
          margin-left: 52px;
          margin-top: 20px;
        }
      }
    }
  }
  .page {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .table {
      min-height: 550px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /deep/.el-table::before {
        background-color: #fff;
      }
    }
    .abnormal {
      width: 49%;
      margin-right: 2%;
      .title {
        border-bottom: 1px solid #eee;
        font-size: 18px;
        height: 57px;
        padding-left: 32px;
        line-height: 57px;
        font-weight: bold;
      }
    }
    .program {
      width: 49%;
      .title {
        border-bottom: 1px solid #eee;
        height: 57px;
        padding-left: 32px;
        line-height: 57px;
        display: flex;
        justify-content: space-between;
        .text {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="search-list">
      <el-input placeholder="请输入关键词查询" v-model="search" class="input-with-select wd500">
        <el-select v-model="select" slot="prepend" placeholder="请选择" class="wd150">
          <el-option
            v-for="item in [{name:'被邀请人',value:1},{name:'邀请人',value:2},]"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
      </el-input>
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <!-- <el-table-column type='selection' width='50' header-align="center" align="center"> </el-table-column> -->
        <el-table-column prop="patienceName" label="被邀请人"></el-table-column>
        <el-table-column prop="invitationTime" label="邀请时间"></el-table-column>
        <el-table-column prop="doctorName" label="邀请人"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "invite",
  data() {
    return {
      select: 1,
      search: "",
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/teamsPatience/getInvitationRecords"),
        method: "post",
        data: {
          stringParam2: this.select == 1 ? this.search : "",
          stringParam3: this.select == 2 ? this.search : "",
          stringParam1: teamsId,
          intParam1: this.pageSize,
          intParam2: this.pageNo,
        },
      });
      if (res.status) {
        this.dataList = res.data;
        this.dataListLoading = false;
        this.totalPage = res.totalCount;
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>

<style>
</style>